<script>
import ItemChallengeFinished from "@/components/item_challenge_finished";
import lodash from "lodash";

export default {
  name: "ChallengeFinished",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    level: {
      type: Number,
    },
    winner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      items: [],
      limit: 10,
      total: 0,
      loading: false,
    };
  },
  watch: {
    page() {
      this.vchallengeMeGet();
    },
  },
  methods: {
    refresh() {
      this.items = [];
      this.total = 0;
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.vchallengeMeGet();
      }
    },
    vchallengeMeGet() {
      this.loading = true;

      const payload = {
        params: {
          skip: (this.page - 1) * this.limit,
          limit: this.summary ? 5 : this.limit,
        },
      };

      if (!lodash.isNil(this.level)) {
        lodash.assign(payload.params, {
          level: this.level,
        });
      }

      if (this.winner) {
        lodash.assign(payload.params, {
          winner: 1,
        });
      }

      console.log(payload);

      this.$store
        .dispatch("vchallengeMeGet", payload)
        .then((response) => {
          this.items = response.data.data.items;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.total = 0;
          this.$store.dispatch("error", this.getErrorMessage(error));
        });
    },
  },
  mounted() {
    this.vchallengeMeGet();
  },
  components: {
    ItemChallengeFinished,
  },
};
</script>

<template>
  <ItemChallengeFinished
    v-for="item in items"
    :item="item"
    :key="`item-challenge-finished-${item._id}`"
  />

  <template v-if="!lodash.isEmpty(items)">
    <pagination
      v-model="page"
      :records="total"
      :per-page="limit"
      :options="{
        hideCount: true,
      }"
    ></pagination>
  </template>

  <template v-if="!loading && lodash.isEmpty(items)">
    <div class="text-center">
      <p class="text-center">
        {{ $t("text.there-are-no-completed-contests-display") }}
      </p>
      <img src="@/assets/images/empty.png" alt="" width="350" />
    </div>
  </template>
</template>

<style >
.pagination {
  justify-content: center !important;
}
</style>