<template>
  <section class="pb-120">
    <div class="container">
      <div class="row mb-none-30 justify-content-center">
        <div class="col-lg-4 col-sm-6 mb-30">
          <div class="icon-item2">
            <div class="icon-item2__icon">
              <img
                src="@/assets/images/icon/contest-feature/1.png"
                alt="image"
              />
            </div>
            <div class="icon-item2__content">
              <h3 class="title">
                {{ $t("text.secure-checkout.title") }}
              </h3>
              <p>
                {{ $t("text.secure-checkout.description") }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 mb-30">
          <div class="icon-item2">
            <div class="icon-item2__icon">
              <img
                src="@/assets/images/icon/contest-feature/2.png"
                alt="image"
              />
            </div>
            <div class="icon-item2__content">
              <h3 class="title">
                {{ $t("text.great-value.title") }}
              </h3>
              <p>
                {{ $t("text.great-value.description") }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 mb-30">
          <div class="icon-item2">
            <div class="icon-item2__icon">
              <img
                src="@/assets/images/icon/contest-feature/3.png"
                alt="image"
              />
            </div>
            <div class="icon-item2__content">
              <h3 class="title">
                {{ $t("text.worldwide-delivery.title") }}
              </h3>
              <p>
                {{ $t("text.worldwide-delivery.description") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>