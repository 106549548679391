<script>
import Feature from "@/components/feature";
import ChallengeFinished from "@/components/challenge_finished.vue";
import lodash from "lodash";
import ContestsMe from "@/components/contests_me.vue";

export default {
  name: "Challenges",
  setup() {
    return {
      lodash: lodash,
    };
  },
  components: {
    Feature,
    ChallengeFinished,
    ContestsMe,
  },
};
</script>

<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section style--four">
    <div class="bg-shape">
      <img src="@/assets/images/elements/inner-hero-shape-2.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li class="active">
              {{ $t("text.contests") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- inner-hero-section end -->

  <!-- contest section start  -->
  <section class="pb-120 mt-minus-150">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="faq-top-wrapper pt-120">
            <div class="section-header text-center">
              <h2 class="section-title">
                {{ $t("text.contests") }}
              </h2>
            </div>
            <ul
              class="nav nav-tabs cmn-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  aria-controls="all"
                  aria-selected="true"
                >
                  {{ $t("text.all") }}
                </a>
              </li>
              <template
                v-if="
                  $store.getters.isAuthenticated &&
                  !lodash.isNil($store.getters.account)
                "
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="participated-tab"
                    data-toggle="tab"
                    href="#participated"
                    role="tab"
                    aria-controls="participated"
                    aria-selected="false"
                  >
                    {{ $t("text.participated") }}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="won-tab"
                    data-toggle="tab"
                    href="#won"
                    role="tab"
                    aria-controls="won"
                    aria-selected="false"
                  >
                    {{ $t("text.won") }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="faq-body-wrapper">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <ChallengeFinished />
              </div>

              <template
                v-if="
                  $store.getters.isAuthenticated &&
                  !lodash.isNil($store.getters.account)
                "
              >
                <div
                  class="tab-pane fade"
                  id="participated"
                  role="tabpanel"
                  aria-labelledby="participated-tab"
                >
                  <ContestsMe />
                </div>

                <div
                  class="tab-pane fade"
                  id="won"
                  role="tabpanel"
                  aria-labelledby="won-tab"
                >
                  <ContestsMe :winner="true" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- contest section end -->

  <Feature />
</template>